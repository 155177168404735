import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'next-i18next';
import _ from 'lodash';
import Slider from 'Components/Slider';
import CardNews from 'Components/CardNews';
import s from './NewsSlider.module.scss';

const NewsSlider = ({title, items, url}) => {
    const {t} = useTranslation();

    if (items.length === 0) {
        return null;
    }

    const displayTitle = _.isEmpty(title) ? t('newsSlider.title') : title;

    let button = null;
    if(!_.isEmpty(url)) {
        button = {
            url: url,
            title: t('newsSlider.buttonTitle'),
        };
    }

    return (
        <div className={s['NewsSlider']}>
            <div className={s['NewsSlider__Wrap']}>
                <div className={s['NewsSlider__Slider']}>
                    <Slider
                        title={displayTitle}
                        items={items}
                        Card={CardNews}
                        button={button}
                    />
                </div>
            </div>
        </div>
    );
};

NewsSlider.propTypes = {
    title: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    url: PropTypes.string,
};

NewsSlider.defaultProps = {
    title: '',
    items: [],
    url: '',
};

export default NewsSlider;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {useTranslation} from 'next-i18next';
import _ from 'lodash';
import {sanitizeText, sanitizeLineBreaks} from 'utils/html';
import DisplayDateTime from 'Components/DisplayDateTime';
import Image from 'Components/Image';
import ArrowLarge from 'Assets/svg/arrowLarge.svg';
import s from './CardNews.module.scss';

const CardNews = ({title, url, image, term, date, excerpt}) => {
    const {t} = useTranslation();

    const sanitizedTitle = sanitizeText(title);
    const sanitizedExcerpt = sanitizeLineBreaks(excerpt);

    const sizes = [
        '(min-width: 1440px) 450px',
        '(min-width: 768px) 33vw',
        '100vw',
    ];

    const classes = classNames(
        s['CardNews'],
        {[s['CardNews--HasImage']]: !_.isEmpty(image)},
    );
    return(
        <article className={classes}>
            <a className={s['CardNews__Link']} href={url}>
                <span
                    className={'sr-only'}
                    dangerouslySetInnerHTML={{__html: sanitizedTitle}}
                />
            </a>

            {!_.isEmpty(image) &&
                <div className={s['CardNews__Image']}>
                    <Image {...image} sizes={sizes} />
                </div>
            }

            <div className={s['CardNews__Content']}>
                <div className={s['CardNews__Meta']}>
                    {!_.isEmpty(term) &&
                        <div className={s['CardNews__Term']}>
                            <span className={'sr-only'}>{t('cardNews.category')}: </span>
                            {term}
                        </div>
                    }

                    <div className={s['CardNews__Date']}><DisplayDateTime startDate={date} /></div>
                </div>
                <h3
                    className={s['CardNews__Title']}
                    dangerouslySetInnerHTML={{__html: sanitizedTitle}}
                />
                <p
                    className={s['CardNews__Text']}
                    dangerouslySetInnerHTML={{__html: sanitizedExcerpt}}
                />

                <div className={s['CardNews__Button']}><ArrowLarge /></div>
            </div>
        </article>
    );
};

CardNews.propTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    image: PropTypes.object,
    term: PropTypes.string,
    date: PropTypes.string,
    excerpt: PropTypes.string,
};

CardNews.defaultProps = {
    title: '',
    url: '',
    image: null,
    term: '',
    date: '',
    excerpt: '',
};

export default CardNews;

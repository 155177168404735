import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'next-i18next';
import _ from 'lodash';
import s from './DisplayDateTime.module.scss';

const DisplayDateTime = ({
    startDate = '',
    endDate = '',
    startTime = '',
    endTime = '',
    display = 'datetime',
}) => {
    if(display === 'datetime') {
        return (
            <DateTime
                startDate={startDate}
                endDate={endDate}
                startTime={startTime}
                endTime={endTime}
            />
        );
    } else if(display === 'date') {
        return (
            <DateDisplay
                startDate={startDate}
                endDate={endDate}
            />
        );
    } else if(display === 'time') {
        return (
            <TimeDisplay
                startTime={startTime}
                endTime={endTime}
            />
        );
    } else {
        return null;
    }
};

DisplayDateTime.propTypes = {
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    display: PropTypes.string.isRequired,
};

const DateTextFormat = (date = '') => {
    const {t} = useTranslation();
    const d = new Date(date);
    const day = d.getDate();
    const month = d.getMonth();
    const year = d.getFullYear();
    if(isNaN(month)) {
        return '';
    } else {
        const monthText = t(`utils.months.${month}`);
        return `${day} ${monthText} ${year}`;
    }
};

const DateSrFormat = (date = '') => {
    const d = new Date(date);
    const day = d.getDate();
    const dayRaw = day < 10 ? `0${day}` : day;
    const month = d.getMonth();
    const monthRaw = month+1 < 10 ? `0${month+1}` : month+1;
    const year = d.getFullYear();
    return `${year}-${monthRaw}-${dayRaw}`;
};

const TimeTextFormat = (time = '') => {
    // Replace space with T for fix in Safari
    const d = new Date(time ? time.replace(' ', 'T') : time);
    const minutes = d.getMinutes();
    const minutesRaw = minutes < 10 ? `0${minutes}` : minutes;
    const hour = d.getHours();
    return `${hour}.${minutesRaw}`;
};

const TimeSrFormat = (time = '') => {
    // Replace space with T for fix in Safari
    const d = new Date(time ? time.replace(' ', 'T') : time);
    const minutes = d.getMinutes();
    const minutesRaw = minutes < 10 ? `0${minutes}` : minutes;
    const hour = d.getHours();
    const hourRaw = hour < 10 ? `0${hour}` : hour;
    return `${hourRaw}:${minutesRaw}`;
};

const DateToFromTextFormat = (from = '', to = '') => {
    const fromDate = DateTextFormat(from);
    if(_.isEmpty(to)) {
        return fromDate;
    } else {
        const toDate = DateTextFormat(to);
        const fromSplit = fromDate.split(' ');
        const toSplit = toDate.split(' ');
        if(fromSplit[2] === toSplit[2]) { // If year doesn't match
            return `${fromDate} - ${toDate}`;
        } else if(fromSplit[1] === toSplit[1]) { // If month doesn't match
            return `${fromSplit[0]} ${fromSplit[1]} - ${toSplit[0]} ${toSplit[1]} ${fromSplit[2]}`;
        } else {
            return `${fromSplit[0]}-${toSplit[0]} ${fromSplit[1]} ${fromSplit[2]}`;
        }
    }
};

const DateTime = ({
    startDate = '',
    endDate = '',
    startTime = '',
    endTime = '',
}) => {
    const {t} = useTranslation();

    const hasDate = !_.isEmpty(startDate);
    const showBothDates = hasDate && !_.isEmpty(endDate) && startDate !== endDate;
    const startDateRaw = DateSrFormat(startDate);
    const endDateRaw = DateSrFormat(endDate);
    const startDateFormatted = DateTextFormat(startDate);
    const endDateFormatted = DateTextFormat(endDate);

    const dateSrTranslation = showBothDates ? 'utils.dateFromToScreenReader' : 'utils.dateScreenReader';
    const startDateHTML = t('utils.formatTimeHTML', {raw: startDateRaw, formatted: startDateFormatted});
    const endDateHTML = t('utils.formatTimeHTML', {raw: endDateRaw, formatted: endDateFormatted});
    const dateSrString = t(dateSrTranslation, {start: startDateHTML, end: endDateHTML});
    const dateString = showBothDates ? DateToFromTextFormat(startDate, endDate) : DateToFromTextFormat(startDate);

    const hasTime = !_.isEmpty(startTime);
    const showBothTimes = hasTime && !_.isEmpty(endTime) && startTime !== endTime;
    const startTimeRaw = TimeSrFormat(startTime);
    const endTimeRaw = TimeSrFormat(endTime);
    const startTimeFormatted = TimeTextFormat(startTime);
    const endTimeFormatted = TimeTextFormat(endTime);

    const startTimeHTML = t('utils.formatTimeHTML', {raw: startTimeRaw, formatted: startTimeFormatted});
    const endTimeHTML = t('utils.formatTimeHTML', {raw: endTimeRaw, formatted: endTimeFormatted});
    const timeSrTranslation = showBothTimes ? 'utils.timeFromToScreenReader' : 'utils.timeScreenReader';
    const timeSrString = t(timeSrTranslation, {start: startTimeHTML, end: endTimeHTML});
    const timeTranslation = showBothTimes ? 'utils.timeFromTo' : 'utils.time';
    const timeString = t(timeTranslation, {start: startTimeFormatted, end: endTimeFormatted});

    const showBoth = hasTime && hasDate;
    const timeDate = showBoth ? t('utils.dateTime', {time: timeString, date: dateString}) : (
        hasTime ? timeString : dateString
    );

    return (
        <>
            {hasDate &&
                <span
                    className={'sr-only'}
                    dangerouslySetInnerHTML={{__html: dateSrString}}
                />
            }

            {hasTime &&
                <span
                    className={'sr-only'}
                    dangerouslySetInnerHTML={{__html: timeSrString}}
                />
            }

            {(hasDate || hasTime) &&
                <span aria-hidden>{timeDate}</span>
            }
        </>
    );
};

DateTime.propTypes = {
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
};

const DateDisplay = ({
    startDate = '',
    endDate = '',
}) => {
    const {t} = useTranslation();
    const hasDate = !_.isEmpty(startDate);
    const showBothDates = hasDate && !_.isEmpty(endDate) && startDate !== endDate;
    const startDateRaw = DateSrFormat(startDate);
    const endDateRaw = DateSrFormat(endDate);
    const startDateFormatted = DateTextFormat(startDate);
    const endDateFormatted = DateTextFormat(endDate);

    const dateSrTranslation = showBothDates ? 'utils.dateFromToScreenReader' : 'utils.dateScreenReader';
    const startDateHTML = t('utils.formatTimeHTML', {raw: startDateRaw, formatted: startDateFormatted});
    const endDateHTML = t('utils.formatTimeHTML', {raw: endDateRaw, formatted: endDateFormatted});
    const dateSrString = t(dateSrTranslation, {start: startDateHTML, end: endDateHTML});
    const dateString = showBothDates ? DateToFromTextFormat(startDate, endDate) : DateToFromTextFormat(startDate);

    if(!hasDate) {
        return null;
    }
    return (
        <>
            <span
                className={'sr-only'}
                dangerouslySetInnerHTML={{__html: dateSrString}}
            />
            <span aria-hidden>{dateString}</span>
        </>
    );
};

DateDisplay.propTypes = {
    startDate: PropTypes.string,
    endDate: PropTypes.string,
};

const TimeDisplay = ({
    startTime = '',
    endTime = '',
}) => {
    const {t} = useTranslation();
    const hasTime = !_.isEmpty(startTime);
    const showBothTimes = hasTime && !_.isEmpty(endTime) && startTime !== endTime;
    const startTimeRaw = TimeSrFormat(startTime);
    const endTimeRaw = TimeSrFormat(endTime);
    const startTimeFormatted = TimeTextFormat(startTime);
    const endTimeFormatted = TimeTextFormat(endTime);

    const startTimeHTML = t('utils.formatTimeHTML', {raw: startTimeRaw, formatted: startTimeFormatted});
    const endTimeHTML = t('utils.formatTimeHTML', {raw: endTimeRaw, formatted: endTimeFormatted});
    const timeSrTranslation = showBothTimes ? 'utils.timeFromToScreenReader' : 'utils.timeScreenReader';
    const timeSrString = t(timeSrTranslation, {start: startTimeHTML, end: endTimeHTML});
    const timeTranslation = showBothTimes ? 'utils.timeFromTo' : 'utils.time';
    const timeString = t(timeTranslation, {start: startTimeFormatted, end: endTimeFormatted});

    if(!hasTime) {
        return null;
    }

    return (
        <>
            <span
                className={'sr-only'}
                dangerouslySetInnerHTML={{__html: timeSrString}}
            />
            <span aria-hidden>{timeString}</span>
        </>
    );
};

TimeDisplay.propTypes = {
    startTime: PropTypes.string,
    endTime: PropTypes.string,
};

export default DisplayDateTime;
